<template>
  <div>
    <div v-if="isLoading" class="d-flex justify-content-center mb-3 pt-4">
      <b-spinner style="width: 5rem; height: 5rem" variant="warning" label="Spinning"></b-spinner>
    </div>
    <div v-else>
      <div class="card no-padding" body-class="pt-0" header-bg-variant="white">
        <div class="card-header">
          <div class="d-flex align-items-center justify-content-between">
            <h1 class="d-inline-block mb-0">
              <i class="far fa-user-circle colorOrange"></i>
              <span class="ml-2" data-test-hook="account-info-title-account-settings"
                >{{ $t('xAccountInformation') }}
              </span>
            </h1>
          </div>
        </div>
        <div class="row mt-4 ml-2">
          <div class="col-auto mb-1">
            <i class="far fa-user colorOrange"></i>
            <strong class="ml-2" data-test-hook="account-holder-name-address-title-account-settings">
              {{ $t('xAccountHolder') }}
            </strong>
          </div>
          <div class="col-auto">
            <popper trigger="hover" :options="{ placement: 'right' }">
              <div class="popper">
                {{ $t('xPrimaryBillingContactAndAddressInfo') }}
              </div>
              <i class="far fa-question-circle" slot="reference"></i>
            </popper>
          </div>
        </div>
        <div class="row mt-1 ml-2">
          <div v-if="!accountInfo" class="d-flex justify-content-center mb-3 pt-4">
            <span
              class="spinner-border spinner-border-xl text-warning"
              style="width: 5rem; height: 5rem"
              data-test-hook="LoadingSpinner"
              label="Spinning"
            ></span>
          </div>
          <div v-if="accountInfo" class="col">
            <p data-test-hook="account-holder-name-account-settings">
              {{ accountInfo.ownerFirstName + ' ' + accountInfo.ownerLastName }}
            </p>
            <p v-if="accountInfo.ownerAddress.line1" data-test-hook="account-holder-address-line-1-account-settings">
              {{ accountInfo.ownerAddress.line1 }}
            </p>
            <p v-if="accountInfo.ownerAddress.line2" data-test-hook="account-holder-address-line-2-account-settings">
              {{ accountInfo.ownerAddress.line2 }}
            </p>
            <p data-test-hook="account-holder-address-city-state-zip-account-settings">
              {{
                accountInfo.ownerAddress.city +
                  ', ' +
                  accountInfo.ownerAddress.region +
                  ' ' +
                  accountInfo.ownerAddress.postalCode
              }}
            </p>
            <p data-test-hook="account-holder-phone-number-account-settings">
              {{ accountInfo.ownerPhoneNumber }}
            </p>
          </div>
        </div>
        <div class="row ml-2 mb-4">
          <div class="col">
            <button
              type="button"
              class="btn btn-outline-warning mt-3"
              @click="openEditAccountModal()"
              :title="$t('xUpdateAccountInfo')"
              data-test-hook="update-account-info-button-account-settings"
            >
              {{ $t('xUpdateAccountInfo') }}
            </button>
          </div>
        </div>
        <div class="row mt-4 ml-2">
          <div class="col-auto mb-1">
            <i class="far fa-lock colorOrange"></i>
            <strong class="ml-2" data-test-hook="account-holder-login-title-account-settings">
              {{ $t('xAccountLogin') }}
            </strong>
          </div>
          <div class="col-auto">
            <popper trigger="hover" :options="{ placement: 'right' }">
              <div class="popper">
                {{ $t('xEmailUsedToLoginAndRegisterMessage') }}
              </div>
              <i slot="reference" class="far fa-question-circle "></i>
            </popper>
          </div>
        </div>
        <div class="row ml-2">
          <div class="col-auto">
            <p v-if="accountProfile.identityEmail" data-test-hook="account-holder-login-email-account-settings">
              {{ accountProfile.identityEmail }}
            </p>
          </div>
        </div>
        <div class="row ml-2 mb-4">
          <div class="col">
            <button
              type="button"
              class="btn btn-outline-warning mt-3"
              @click="updateEmail(accountProfile.identityEmail)"
              :title="$t('xUpdateAccountEmail')"
              data-test-hook="update-account-email-button-account-settings"
            >
              {{ $t('xUpdateAccountEmail') }}
              <i class="fas fa-external-link" />
            </button>
          </div>
        </div>
        <div v-if="subscription">
          <div class="row mt-4 ml-2">
            <div class="col-auto mb-1">
              <i class="far fa-credit-card colorOrange"></i>
              <strong class="ml-2" data-test-hook="account-holder-payment-information-account-settings">
                {{ $t('xPaymentInformation') }}
              </strong>
            </div>
            <div class="col-auto">
              <popper trigger="hover" :options="{ placement: 'right' }">
                <div class="popper">
                  {{ $t('xPrimaryPaymentInformationMessage') }}
                </div>
                <i slot="reference" class="far fa-question-circle "></i>
              </popper>
            </div>
          </div>
          <div class="row ml-2">
            <div class="col-auto">
              <p data-test-hook="account-holder-payment-information-card-account-settings">
                {{ subscription.cardType.toUpperCase() + ' - ' + subscription.cardSuffix }}
              </p>
            </div>
          </div>
          <div class="row ml-2">
            <div class="col-auto">
              <p data-test-hook="account-holder-payment-information-expiration-account-settings">
                {{
                  $t('xExpires') + ' ' + displayMonth(subscription.expirationMonth) + '/' + subscription.expirationYear
                }}
              </p>
            </div>
          </div>
          <div v-if="shouldShowUpdatePayment" class="row ml-2 mb-4">
            <div class="col">
              <button
                type="button"
                class="btn btn-outline-warning mt-2"
                @click="openModal('update-payment-method')"
                :title="$t('xUpdatePaymentInformation')"
                data-test-hook="update-payment-information-button-account-settings"
              >
                {{ $t('xUpdatePaymentInformation') }}
              </button>
            </div>
          </div>
          <div v-if="shouldShowRemovePayment" class="row ml-2 mb-4">
            <div class="col">
              <button
                type="button"
                class="btn btn-outline-danger rounded-pill mt-4"
                @click="removePaymentInformation()"
                :title="$t('xRemovePaymentInformation')"
                data-test-hook="remove-payment-information-button-account-settings"
              >
                <i class="fas fa-trash-alt colorOrange"></i>
                {{ $t('xRemovePaymentInformation') }}
                <span class="spinner-border ml-2" v-if="removing"></span>
              </button>
            </div>
          </div>
        </div>
        <div class="row ml-2 mr-2">
          <div class="col">
            <hr class="mx-0 px-0" />
          </div>
        </div>
        <div class="row ml-2 mb-4">
          <div class="col">
            <button
              type="button"
              class="btn btn-outline-danger rounded-pill mt-4"
              @click="deleteAccount()"
              :disabled="isImpersonating"
              :title="deleteAccountTitle()"
              data-test-hook="delete-account-button-account-settings"
            >
              <i class="fas fa-trash-alt colorOrange"></i>
              {{ $t('xDeleteAccount') }}
            </button>
          </div>
        </div>
      </div>
      <Modal modalId="unable-to-delete-account-modal" size="modal-lg">
        <ModalHeader :title="$t('xUnableToDeleteAccount')" icon="fa-regular fa-trash-can">
          <button
            class="btn btn-icon-button btn-icon-button--gray"
            @click="closeModal('unable-to-delete-account-modal')"
            data-test-hook="unable-to-delete-account-x-button"
          >
            <i class="fas fa-times" />
          </button>
        </ModalHeader>
        <ModalBody>
          <p v-html="$t('xVppDeleteAccountModalBody1')" data-test-hook="modalBodyText1" />
          <p class="mt-3" data-test-hook="modalBodyText2">
            {{ $t('xVppDeleteAccountModalBody2') }}
          </p>
        </ModalBody>
        <ModalFooter>
          <button
            class="btn btn-primary"
            @click="closeModal('unable-to-delete-account-modal')"
            data-test-hook="modalDismissButton"
          >
            {{ $t('xDismiss') }}
          </button>
        </ModalFooter>
      </Modal>

      <Modal modalId="update-payment-method" size="modal-md" @hidden="closePaymentModal()">
        <ModalHeader :title="$t('xUpdatePaymentMethod')" icon="fal fa-credit-card colorOrange mr-1">
          <div class="cols-auto">
            <button
              type="button"
              class="btn btn-icon-button btn-icon-button--gray"
              @click="closePaymentModal()"
              data-test-hook="CloseModalBtn"
            >
              <i class="fas fa-times" />
            </button>
          </div>
        </ModalHeader>
        <ModalBody>
          <div role="alert" v-if="showValidationBanner" class="alert alert-danger" data-test-hook="ValidationAlert">
            <p>{{ $t('xExperiencedTheseErrors') }}</p>
            <ul v-for="(message, index) in errorMessages" :key="index">
              <li :id="index" data-test-hook="ValidationMsg">
                {{ message }}
              </li>
            </ul>
          </div>
          <div class="row">
            <div class="col-12">
              <h3>
                <strong data-test-hook="CcInfoHeader">{{ $t('xCreditCardInfo') }}</strong>
                <span class="body-text-color">*</span>
              </h3>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col">
              <i class="asterisk-text" data-test-hook="UpdatePaymentWarningMsg">
                {{ $t('xUpdatingPaymentWarning') }}
              </i>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group" id="credit-card-number">
                <input
                  class="form-control"
                  id="creditCardNumber"
                  v-model="$v.creditCardNumber.$model"
                  maxlength="16"
                  type="text"
                  :class="status($v.creditCardNumber)"
                  v-mask="'################'"
                  :placeholder="$t('xCreditCardNumber')"
                  aria-describedby="card-number-invalid"
                  data-test-hook="CcNumber"
                />
                <div class="invalid-feedback" id="card-number-invalid" data-test-hook="invalidCcNo">
                  {{ $t('xCCFieldValidation') }}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col col-lg-6 col-md-6 col-sm-12">
              <div class="form-group" id="expiry-date-month">
                <input
                  class="form-control"
                  id="xNm"
                  v-model="$v.expirationMonth.$model"
                  maxlength="2"
                  type="text"
                  :class="status($v.expirationMonth)"
                  v-mask="'##'"
                  :placeholder="$t('xMm')"
                  aria-describedby="expiry-month-invalid"
                  data-test-hook="CcExpireMonth"
                />
                <div class="invalid-feedback" id="expiry-month-invalid" data-test-hook="invalidCcMth">
                  {{ $t('xExpirationMonthValidation') }}
                </div>
              </div>
            </div>
            <div class="col col-lg-6 col-md-t6 col-sm-12">
              <div class="form-group" id="expiry-date-year">
                <input
                  class="form-control"
                  v-model="$v.expirationYear.$model"
                  type="text"
                  :placeholder="$t('xYy')"
                  maxlength="2"
                  v-mask="'##'"
                  :class="status($v.expirationYear)"
                  aria-describedby="expiry-year-invalid"
                  data-test-hook="CcExpireYear"
                />
                <div class="invalid-feedback" id="expiry-year-invalid" data-test-hook="invalidCcYear">
                  {{ $t('xExpirationYearValidation') }}
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="input-group" id="cvv">
                <input
                  class="form-control"
                  v-model="$v.cvv.$model"
                  type="text"
                  v-mask="'####'"
                  maxlength="4"
                  :placeholder="$t('xCvv')"
                  :class="status($v.cvv)"
                  aria-describedby="cvv-invalid"
                  data-test-hook="CcCvv"
                />
                <div class="input-group-append">
                  <div class="input-group-text">
                    <img :height="20" :src="require('@/assets/images/3-digit-code.svg')" data-test-hook="ccCvvImage" />
                  </div>
                </div>
                <div class="invalid-feedback" id="cvv-invalid" data-test-hook="invalidCcCvv">
                  {{ $t('xCVVValidation') }}
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4" v-if="accountData">
            <div class="row mb-2">
              <div class="col">
                <div class="font-weight-bold custom-control custom-checkbox">
                  <input
                    id="useExistingBillingInformation"
                    type="checkbox"
                    class="custom-control-input"
                    v-model="useExistingBillingInformation"
                    :disabled="savingAccountHolder"
                    data-test-hook="UseCurrentBillingAddressChkBox"
                  />
                  <label
                    class="custom-control-label"
                    for="useExistingBillingInformation"
                    data-test-hook="useCurrBillingInfoText"
                  >
                    {{ $t('xUseCurrentBillingInfo') }}
                  </label>
                </div>
              </div>
            </div>
            <div class="row" v-if="useExistingBillingInformation">
              <div class="col-12" data-test-hook="ExistingBillingName">
                {{ accountData.firstName }} {{ accountData.lastName }}
              </div>
              <div class="col-12" data-test-hook="ExistingBillingAddress">
                {{ DisplayAddress(accountData.address) }}
              </div>
            </div>
            <div v-if="!useExistingBillingInformation">
              <div class="row">
                <div class="col-12">
                  <fieldset id="firstName-group" class="form-group">
                    <div>
                      <input
                        class="form-control"
                        type="text"
                        id="firstName"
                        v-model="accountData.firstName"
                        data-test-hook="firstName"
                      />
                    </div>
                  </fieldset>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <fieldset class="form-group" id="lastName-group">
                    <div>
                      <input
                        class="form-control"
                        id="lastName"
                        v-model="accountData.lastName"
                        type="text"
                        data-test-hook="lastName"
                      />
                    </div>
                  </fieldset>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <TypeAheadAddress
                    ref="typeAheadAddressSubscription"
                    :currentAddress="accountData.address"
                    @newAddress="setNewBillingAddress"
                  />
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            class="btn btn-outline-warning"
            @click="closeModal('update-payment-method'), closePaymentModal()"
            data-test-hook="CancelBtn"
          >
            {{ $t('xCancel') }}
          </button>
          <button
            type="button"
            v-if="useExistingBillingInformation"
            class="btn btn-primary"
            @click="updatePaymentInformation()"
            :disabled="!canPay()"
            data-test-hook="SaveChangesBtn"
          >
            {{ $t('xSave') }}
            <span class="spinner-border ml-2" v-if="paying"></span>
          </button>
          <button
            v-else
            type="button"
            class="btn btn-warning"
            @click="saveAccountHolder(), (savingAccountHolder = true)"
            :disabled="savingAccountHolder"
            data-test-hook="SaveChangesBtn"
          >
            {{ $t('xConfirmBillingInfo') }}
            <span class="spinner-border ml-2" v-if="savingAccountHolder"></span>
          </button>
        </ModalFooter>
      </Modal>

      <EditAccountInfoModal :shouldSetModal="shouldSetModal" @shouldSetModal="setShouldSetModal()" />
      <DeleteAccountModal
        :deleteAccountModalInfo="deleteAccountModalInfo"
        @showConfirmDeleteAccountModal="showConfirmDeleteAccountModal"
      />
      <ConfirmDeleteAccountModal :confirmDeleteAccountModalInfo="confirmDeleteAccountModalInfo" />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { required, requiredIf, minLength, maxLength, email, numeric } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import TypeAheadAddress from '@/components/TypeAheadAddress'
import EditAccountInfoModal from '@/components/AccountSettings/EditAccountInfoModal'
import DeleteAccountModal from '@/components/AccountSettings/DeleteAccountModal'
import ConfirmDeleteAccountModal from '@/components/AccountSettings/ConfirmDeleteAccountModal'

const monthInRange = (value, vm) => value >= 1 && value <= 12

export default {
  components: { TypeAheadAddress, EditAccountInfoModal, DeleteAccountModal, ConfirmDeleteAccountModal },
  mixins: [validationMixin],
  data() {
    return {
      confirmDeleteAccountModalInfo: {},
      deleteAccountModalInfo: {},
      shouldSetModal: false,
      userInfo: {},
      subscription: null,
      billingInfoLoading: true,
      existingPaymentInfo: null,
      paying: false,
      creditCardNumber: null,
      expirationMonth: null,
      expirationYear: null,
      cvv: null,
      showValidationBanner: false,
      accountData: null,
      useExistingBillingInformation: true,
      savingAccountHolder: false,
      errorMessages: [],
      shouldShowUpdatePayment: false,
      shouldShowRemovePayment: false,
      removing: false,
      isLoading: true
    }
  },
  validations: {
    cvv: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(4),
      numeric
    },
    expirationMonth: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(2),
      numeric,
      monthInRange
    },
    expirationYear: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(2),
      numeric
    },
    creditCardNumber: {
      required,
      min: minLength(15),
      max: maxLength(16),
      minLength: minLength(15),
      maxLength: maxLength(16),
      numeric
    }
  },
  computed: {
    ...mapState({
      apparatuses: state => state.apparatus.apparatuses,
      accountProfile: state => state.account.accountProfile,
      currentAppConfig: state => state.application.currentAppConfig,
      user: state => state.account.user,
      accountInfo: state => state.account.accountInfo,
      isImpersonating: state => state.auth.isImpersonating
    })
  },
  methods: {
    setShouldSetModal() {
      this.shouldSetModal = !this.shouldSetModal
    },
    openEditAccountModal() {
      this.shouldSetModal = true
      this.openModal('EditAccountInfoModal')
    },
    deleteAccount() {
      if (this.accountProfile && this.accountProfile.enrolledInVpp) {
        this.openModal('unable-to-delete-account-modal')
      } else {
        this.deleteAccountModalInfo = {
          orgId: this.accountProfile.organizationId
        }
        this.openModal('DeleteAccountModal')
      }
    },
    closeDeleteAccountModal() {
      document.getElementById('DeleteAccountModal').style.display = 'none'
    },
    displayConfirmDeleteAccountModal(orgId) {
      this.confirmDeleteAccountModalInfo = {
        orgId: orgId
      }
      document.getElementById('ConfirmDeleteAccountModal').style.display = 'block'
    },
    showConfirmDeleteAccountModal(info) {
      this.closeDeleteAccountModal()
      this.displayConfirmDeleteAccountModal(info.orgId)
    },
    deleteAccountTitle() {
      return this.isImpersonating ? this.$t('xNotPermittedWhileImpersonating') : ''
    },
    setNewBillingAddress(newAddress) {
      this.accountData.address = newAddress
      if (this.$refs.typeAheadAddressSubscription.$v.$anyError) {
        this.addressSaveDisabled = true
      } else {
        this.addressSaveDisabled = false
      }
    },
    saveAccountHolder() {
      this.savingAccountHolder = true
      this.$store
        .dispatch('updateAccountHolder', {
          firstName: this.accountData.firstName,
          lastName: this.accountData.lastName,
          organizationAddress: {
            ...this.accountData.address,
            organizationId: this.accountData.organizationId
          }
        })
        .then(response => {
          if (!this.isImpersonating) this.$store.dispatch('getUser')
          else this.$store.dispatch('getImpersonatedUser')
          this.$store.dispatch('getAccountProfile')
          this.timeTheAccountChange()
        })
        .catch(error => {
          this.$store.dispatch('displayToast', {
            title: this.$t('xError'),
            message: this.$t('xFailedToUpdateBillingNameAndAddress'),
            variant: 'danger'
          })
        })
    },
    timeTheAccountChange() {
      setTimeout(
        function() {
          this.savingAccountHolder = false
          this.useExistingBillingInformation = true
        }.bind(this),
        5000
      )
    },
    updatePaymentInformation() {
      this.paying = true
      let addPaymentModel = {
        organizationId: this.accountProfile.organizationId,
        cardNumber: this.creditCardNumber,
        cvv: this.cvv,
        expirationMonth: this.expirationMonth,
        expirationYear: '20' + this.expirationYear
      }
      if (this.existingPaymentInfo) {
        let removePaymentModel = {
          organizationId: this.accountProfile.organizationId,
          paymentId: this.existingPaymentInfo.id
        }
        this.$store
          .dispatch('addBillingAccountPaymentMethod', addPaymentModel)
          .then(response => {
            this.$store
              .dispatch('getActivePaymentMethod', this.accountProfile.organizationId)
              .then(response => {
                this.subscription = response.data
              })
              .catch(error => {
                this.subscription = null
              })
            this.$store.dispatch('displayToast', {
              title: this.$t('xSuccess'),
              message: this.$t('xPaymentMethodUpdated'),
              variant: 'success'
            })
            this.closePaymentModal()
          })
          .catch(error => {
            this.paying = false
            this.errorMessages = []
            if (error.response.data.errors) {
              if (error.response.data.errors.CardNumber || error.response.data.errors.cardNumber) {
                this.showValidationBanner = true
                this.errorMessages.push(this.$t('xCCIsInvalid'))
              }
              if (error.response.data.errors.Cvv || error.response.data.errors.cvv) {
                this.showValidationBanner = true
                this.errorMessages.push(this.$t('xCvvInvalid'))
              }
              if (error.response.data.errors.ExpirationMonth || error.response.data.errors.expirationMonth) {
                this.showValidationBanner = true
                this.errorMessages.push(this.$t('xExpirationMonthInvalid'))
              }
              if (error.response.data.errors.ExpirationYear || error.response.data.errors.expirationYear) {
                this.showValidationBanner = true
                this.errorMessages.push(this.$t('xExpirationYearInvalid'))
              }
              if (error.response.data.errors.billing || error.response.data.errors.Billing) {
                this.$store.dispatch('displayToast', {
                  title: this.$t('xError'),
                  message: error.response.data.errors.billing
                    ? error.response.data.errors.billing
                    : error.data.response.errors.Billing,
                  variant: 'danger'
                })
              }
            } else {
              this.$store.dispatch('displayToast', {
                title: this.$t('xUpdateFailed'),
                message: this.$t('xUnableToUpdatePaymentInfo'),
                variant: 'danger'
              })
            }
          })
      }
    },
    closePaymentModal() {
      this.creditCardNumber = null
      this.expirationMonth = null
      this.expirationYear = null
      this.cvv = null
      this.paying = false
      this.showValidationBanner = false
      this.errorMessages = []
      this.$v.$reset()
      this.closeModal('update-payment-method')
    },
    canPay() {
      if (!this.creditCardNumber || !this.cvv || !this.expirationMonth || !this.expirationYear) {
        return false
      }
      if (!this.$v.$anyError && !this.paying) {
        return true
      } else return false
    },
    displayMonth(month) {
      let monthString = String(month)
      if (monthString.length === 1) return '0' + month
      else return month
    },
    async hasActiveSubscriptions() {
      await this.$store.dispatch('getApparatuses')
      let result = false
      if (this.apparatuses != null) {
        let index = 0
        while (index < this.apparatuses.length) {
          await this.$store
            .dispatch('getApparatusSubscription', {
              organizationId: this.accountProfile.organizationId,
              apparatusId: this.apparatuses[index].apparatusId
            })
            .then(response => {
              if ('activeProduct' in response.data && response.data.activeProduct.length > 0) {
                if (response.data.activeProduct[0].cost != 0) {
                  this.shouldShowUpdatePayment = true
                  result = true
                }
              }
            })
            .catch(error => {})
          index++
        }
      }
      if (!result) this.shouldShowRemovePayment = true
      return result
    },
    async removePaymentInformation() {
      this.removing = true
      let removePaymentModel = {
        organizationId: this.accountProfile.organizationId,
        paymentId: this.existingPaymentInfo.id
      }
      this.$store
        .dispatch('removeBillingAccountPaymentMethod', removePaymentModel)
        .then(response => {
          this.subscription = null
          this.$store.dispatch('displayToast', {
            title: this.$t('xSuccess'),
            message: this.$t('xPaymentMethodRemoved'),
            variant: 'success'
          })
          this.removing = false
        })
        .catch(error => {
          this.$store.dispatch('displayToast', {
            title: this.$t('xRemoveFailed'),
            message: this.$t('xUnableToRemovePaymentInfo'),
            variant: 'danger'
          })
          this.removing = false
        })
    },
    async getSubscription() {
      let result = null
      await this.$store
        .dispatch('getActivePaymentMethod', this.accountProfile.organizationId)
        .then(response => {
          result = response.data
        })
        .catch(error => {})
      return result
    }
  },
  watch: {
    accountProfile: function(newVal, oldVal) {
      if (newVal) {
        this.accountData = _.cloneDeep(this.accountProfile)
        if (
          this.currentAppConfig &&
          this.currentAppConfig.featureFlags &&
          this.currentAppConfig.featureFlags[this.billingMigrationFeatureFlag]
        ) {
          this.getSubscription()
          this.hasActiveSubscriptions()
        } else {
          this.$store
            .dispatch('getActivePaymentMethod', this.accountProfile.organizationId)
            .then(response => {
              this.existingPaymentInfo = response.data
              this.billingInfoLoading = false
            })
            .catch(error => {
              this.existingPaymentInfo = null
              this.billingInfoLoading = false
            })
          this.getSubscription()
          this.hasActiveSubscriptions()
        }
      }
    }
  },
  async mounted() {
    if (!this.currentAppConfig) this.$store.dispatch('getApplicationConfiguration', 'Any')
    this.$store.dispatch('getAccountInfo')
    this.subscription = await this.getSubscription()
    if (this.accountProfile) {
      this.accountData = _.cloneDeep(this.accountProfile)
      if (
        this.currentAppConfig &&
        this.currentAppConfig.featureFlags &&
        this.currentAppConfig.featureFlags[this.billingMigrationFeatureFlag]
      ) {
        await this.getSubscription()
        await this.hasActiveSubscriptions()
      } else {
        this.$store
          .dispatch('getActivePaymentMethod', this.accountProfile.organizationId)
          .then(response => {
            this.existingPaymentInfo = response.data
            this.billingInfoLoading = false
          })
          .catch(error => {
            this.existingPaymentInfo = null
            this.billingInfoLoading = false
          })
        await this.getSubscription()
        await this.hasActiveSubscriptions()
      }
    } else {
      this.$store.dispatch('getAccountProfile')
    }
    this.isLoading = false
  }
}
</script>
<style scoped>
*.bold-text {
  font-weight: bold;
}
*.btn-outline-danger {
  border: 2px solid;
}
</style>
