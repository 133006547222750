var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "app" } }, [
    !_vm.userLoaded && !_vm.allowedUnprotected
      ? _c(
          "div",
          { staticClass: "d-flex justify-content-center mb-3 pt-5" },
          [
            _c("b-spinner", {
              staticStyle: { width: "5em", height: "5rem" },
              attrs: { variant: "warning", label: "Spinning" }
            })
          ],
          1
        )
      : !_vm.userLoaded && _vm.allowedUnprotected
      ? _c(
          "div",
          [
            _c(
              "b-container",
              { staticClass: "wrapper", attrs: { fluid: "" } },
              [
                _c(
                  "transition",
                  { attrs: { name: "fade", mode: "out-in" } },
                  [_c("router-view")],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      : _c(
          "div",
          [
            _vm.$route.name != "home" &&
            _vm.$route.name != "landingDownload" &&
            _vm.$route.name != "qrScan" &&
            _vm.$route.name != "signup"
              ? _c(
                  "b-navbar",
                  {
                    staticClass: "title-nav-bar",
                    attrs: { sticky: "", "data-test-hook": "NavBar" }
                  },
                  [
                    _c(
                      "b-navbar-brand",
                      {
                        staticClass: "hoverPointer",
                        on: { click: _vm.brandClicked }
                      },
                      [
                        _c("b-img", {
                          attrs: {
                            height: "50px",
                            src: require("@/assets/images/MobileLink_Logo_2023_COLOR.svg")
                          }
                        })
                      ],
                      1
                    ),
                    _vm.user
                      ? _c(
                          "b-collapse",
                          {
                            staticClass: "d-none d-sm-block title-nav-bar",
                            attrs: { id: "nav-collapse", "is-nav": "" }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "d-none d-md-block ml-auto" },
                              [
                                _c(
                                  "b-navbar-nav",
                                  { staticClass: "ml-auto title-nav-bar" },
                                  [
                                    _vm.isImpersonating
                                      ? _c("b-nav-item", [
                                          _vm._v(
                                            "Impersonating: " +
                                              _vm._s(_vm.impersonatedEmail)
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm.user.groups.includes(_vm.adminGroup)
                                      ? _c(
                                          "b-nav-item",
                                          {
                                            attrs: {
                                              to: { name: "admin" },
                                              "data-test-hook": "AdminButton"
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fa-solid fa-screwdriver-wrench py-0 px-0 pr-2"
                                            }),
                                            _c("p", { staticClass: "my-2" }, [
                                              _vm._v(_vm._s(_vm.$t("xAdmin")))
                                            ])
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "b-nav-item",
                                      {
                                        attrs: {
                                          to: { name: "dashboard" },
                                          "data-test-hook": "appDashboardBtn"
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "far fa-house py-0 px-0 mr-2 fa-fw"
                                        }),
                                        _c("p", { staticClass: "mb-0" }, [
                                          _vm._v("Dashboard")
                                        ])
                                      ]
                                    ),
                                    _vm.messageCenterEnabled(
                                      _vm.currentAppConfig
                                    ) && _vm.showMessageIcon()
                                      ? _c(
                                          "b-nav-item",
                                          {
                                            attrs: {
                                              to: { name: "messageCenter" },
                                              "data-test-hook":
                                                "appMsgCenterBtn"
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "position-relative"
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "message-icon far fa-message-lines py-0 px-0 mr-2 fa-fw"
                                                }),
                                                _vm.hasUnreadMessage
                                                  ? _c("i", {
                                                      staticClass:
                                                        "fas fa-circle colorOrange has-unread-message",
                                                      attrs: {
                                                        "data-test-hook":
                                                          "appUnreadMsgCircle"
                                                      }
                                                    })
                                                  : _vm._e()
                                              ]
                                            ),
                                            _c(
                                              "p",
                                              {
                                                staticClass: "mb-0",
                                                attrs: {
                                                  "data-test-hook":
                                                    "appMsgCenterBtnText"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("xMessageCenter")
                                                  )
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "b-nav-item",
                                      {
                                        attrs: {
                                          to: { name: "support" },
                                          "data-test-hook": "appSupportBtn"
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "far fa-question-circle py-0 px-0 mr-2 fa-fw"
                                        }),
                                        _c(
                                          "p",
                                          {
                                            staticClass: "mb-0",
                                            attrs: {
                                              "data-test-hook":
                                                "appSupportBtnText"
                                            }
                                          },
                                          [_vm._v(_vm._s(_vm.$t("xSupport")))]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "b-dropdown",
                                      {
                                        attrs: {
                                          size: "sm",
                                          variant: "nav-dropdown",
                                          right: "",
                                          "data-test-hook": "Menu"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "button-content",
                                              fn: function() {
                                                return [
                                                  _c("i", {
                                                    staticClass:
                                                      "far fa-user-circle fa-fw"
                                                  }),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "ml-2 text-capitalize font-weight-normal",
                                                      staticStyle: {
                                                        "font-size":
                                                          "1.125rem !important"
                                                      },
                                                      attrs: {
                                                        "data-test-hook": "User"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.user.firstName
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm.user.lastName.substring(
                                                              0,
                                                              1
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          false,
                                          1062857163
                                        )
                                      },
                                      [
                                        !_vm.user.groups.includes(
                                          _vm.adminGroup
                                        ) ||
                                        (_vm.user.groups.includes(
                                          _vm.adminGroup
                                        ) &&
                                          _vm.isImpersonating)
                                          ? _c(
                                              "b-dropdown-item",
                                              {
                                                attrs: {
                                                  id: "grayText",
                                                  to: {
                                                    name: "accountInformation"
                                                  },
                                                  "data-test-hook":
                                                    "AccountButton"
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "far fa-user-circle colorOrange"
                                                }),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("xAccountSettings")
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _c("b-dropdown-divider"),
                                        _c(
                                          "b-dropdown-item",
                                          {
                                            attrs: {
                                              "data-test-hook": "SignOut",
                                              id: "grayText"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.signOut()
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fas fa-sign-out-alt colorOrange fa-fw"
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("xLogOut")) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "b-nav-item-dropdown",
                      {
                        staticClass: "d-md-none ml-auto",
                        attrs: {
                          "no-caret": "",
                          right: "",
                          "toggle-class": "mobile-nav-button"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "button-content",
                              fn: function() {
                                return [_c("i", { staticClass: "fas fa-bars" })]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          596374761
                        )
                      },
                      [
                        _c(
                          "b-dropdown-item",
                          {
                            staticClass: "mobile-nav-item",
                            attrs: { to: { name: "dashboard" } }
                          },
                          [
                            _c("i", {
                              staticClass: "far colorOrange fa-house fa-fw"
                            }),
                            _c("span", { staticClass: "ml-2" }, [
                              _vm._v(_vm._s(_vm.$t("xDashboard")))
                            ])
                          ]
                        ),
                        _c(
                          "b-dropdown-item",
                          {
                            staticClass: "mobile-nav-item",
                            attrs: { to: { name: "support" } }
                          },
                          [
                            _c("i", {
                              staticClass:
                                "far colorOrange fa-question-circle fa-fw"
                            }),
                            _c("span", { staticClass: "ml-2" }, [
                              _vm._v(_vm._s(_vm.$t("xSupport")))
                            ])
                          ]
                        ),
                        _vm.messageCenterEnabled(_vm.currentAppConfig) &&
                        _vm.showMessageIcon()
                          ? _c(
                              "b-dropdown-item",
                              {
                                staticClass: "mobile-nav-item",
                                attrs: { to: { name: "messageCenter" } }
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "position-relative" },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "far fa-message-lines fa-fw colorOrange"
                                    }),
                                    _vm.hasUnreadMessage
                                      ? _c("i", {
                                          staticClass:
                                            "fas fa-circle colorOrange has-unread-message"
                                        })
                                      : _vm._e()
                                  ]
                                ),
                                _c("span", { staticClass: "ml-2" }, [
                                  _vm._v(_vm._s(_vm.$t("xMessageCenter")))
                                ])
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "b-dropdown-item",
                          {
                            staticClass: "mobile-nav-item",
                            attrs: { to: { name: "accountInformation" } }
                          },
                          [
                            _c("i", {
                              staticClass:
                                "far colorOrange fa-user-circle fa-fw"
                            }),
                            _c("span", { staticClass: "ml-2" }, [
                              _vm._v(_vm._s(_vm.$t("xAccountSettings")))
                            ])
                          ]
                        ),
                        _c("b-dropdown-divider"),
                        _c(
                          "b-dropdown-item",
                          {
                            staticClass: "mobile-nav-item",
                            on: {
                              click: function($event) {
                                return _vm.signOut()
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass:
                                "far colorOrange fa-sign-out-alt fa-fw"
                            }),
                            _c("span", { staticClass: "ml-2" }, [
                              _vm._v(_vm._s(_vm.$t("xLogOut")))
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "b-container",
              { staticClass: "wrapper", attrs: { fluid: "" } },
              [
                _c(
                  "transition",
                  { attrs: { name: "fade", mode: "out-in" } },
                  [_c("router-view")],
                  1
                )
              ],
              1
            ),
            _vm.$route.name != "home" && _vm.$route.name != "createAccount"
              ? _c(
                  "footer",
                  {
                    staticClass: "footer pl-3 my-3",
                    attrs: { sticky: "true" }
                  },
                  [
                    _c(
                      "b-link",
                      {
                        staticClass: "mr-3 footer-link",
                        attrs: { to: { name: "support" } }
                      },
                      [_vm._v("Contact Us")]
                    ),
                    _c(
                      "b-link",
                      {
                        staticClass: "mr-3 footer-link",
                        attrs: {
                          href: _vm.termsConditionsUrl,
                          target: "_blank"
                        }
                      },
                      [_vm._v(" Terms & Conditions ")]
                    ),
                    _c(
                      "b-link",
                      {
                        staticClass: "mr-3 footer-link",
                        attrs: { href: _vm.privacyPolicyUrl, target: "_blank" }
                      },
                      [_vm._v(" Privacy Statement ")]
                    ),
                    _c(
                      "b-link",
                      {
                        staticClass: "mr-3 footer-link",
                        attrs: { href: _vm.eulaUrl, target: "_blank" }
                      },
                      [_vm._v(" End User License Agreement ")]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c("Toast"),
            _c("FeedbackModal"),
            _vm.showBannerMessage(_vm.bannerMessage)
              ? _c("BannerMessageModal")
              : _vm._e()
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }