<template>
  <div id="app">
    <div v-if="!userLoaded && !allowedUnprotected" class="d-flex justify-content-center mb-3 pt-5">
      <b-spinner style="width: 5em; height: 5rem;" variant="warning" label="Spinning"></b-spinner>
    </div>
    <div v-else-if="!userLoaded && allowedUnprotected">
      <b-container fluid class="wrapper">
        <transition name="fade" mode="out-in">
          <router-view />
        </transition>
      </b-container>
    </div>
    <div v-else>
      <b-navbar
        sticky
        data-test-hook="NavBar"
        class="title-nav-bar"
        v-if="
          $route.name != 'home' &&
            $route.name != 'landingDownload' &&
            $route.name != 'qrScan' &&
            $route.name != 'signup'
        "
      >
        <b-navbar-brand class="hoverPointer" @click="brandClicked">
          <b-img height="50px" :src="require('@/assets/images/MobileLink_Logo_2023_COLOR.svg')" />
        </b-navbar-brand>
        <b-collapse id="nav-collapse" class="d-none d-sm-block title-nav-bar" is-nav v-if="user">
          <div class="d-none d-md-block ml-auto">
            <b-navbar-nav class="ml-auto title-nav-bar">
              <b-nav-item v-if="isImpersonating">Impersonating: {{ impersonatedEmail }}</b-nav-item>
              <b-nav-item :to="{ name: 'admin' }" v-if="user.groups.includes(adminGroup)" data-test-hook="AdminButton">
                <i class="fa-solid fa-screwdriver-wrench py-0 px-0 pr-2"></i>
                <p class="my-2">{{ $t('xAdmin') }}</p>
              </b-nav-item>
              <b-nav-item :to="{ name: 'dashboard' }" data-test-hook="appDashboardBtn">
                <i class="far fa-house py-0 px-0 mr-2 fa-fw" />
                <p class="mb-0">Dashboard</p>
              </b-nav-item>
              <b-nav-item
                :to="{ name: 'messageCenter' }"
                v-if="messageCenterEnabled(currentAppConfig) && showMessageIcon()"
                data-test-hook="appMsgCenterBtn"
              >
                <span class="position-relative">
                  <i class="message-icon far fa-message-lines py-0 px-0 mr-2 fa-fw" />
                  <i
                    v-if="hasUnreadMessage"
                    class="fas fa-circle colorOrange has-unread-message"
                    data-test-hook="appUnreadMsgCircle"
                  />
                </span>
                <p class="mb-0" data-test-hook="appMsgCenterBtnText">{{ $t('xMessageCenter') }}</p>
              </b-nav-item>

              <b-nav-item :to="{ name: 'support' }" data-test-hook="appSupportBtn">
                <i class="far fa-question-circle py-0 px-0 mr-2 fa-fw" />
                <p class="mb-0" data-test-hook="appSupportBtnText">{{ $t('xSupport') }}</p>
              </b-nav-item>
              <b-dropdown size="sm" variant="nav-dropdown" right data-test-hook="Menu">
                <template right v-slot:button-content>
                  <i class="far fa-user-circle fa-fw"></i>
                  <span
                    class="ml-2 text-capitalize font-weight-normal"
                    style="font-size: 1.125rem !important;"
                    data-test-hook="User"
                  >
                    {{ user.firstName }} {{ user.lastName.substring(0, 1) }}
                  </span>
                </template>
                <b-dropdown-item
                  id="grayText"
                  :to="{ name: 'accountInformation' }"
                  v-if="!user.groups.includes(adminGroup) || (user.groups.includes(adminGroup) && isImpersonating)"
                  data-test-hook="AccountButton"
                >
                  <i class="far fa-user-circle colorOrange"></i>
                  {{ $t('xAccountSettings') }}
                </b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item @click="signOut()" data-test-hook="SignOut" id="grayText">
                  <i class="fas fa-sign-out-alt colorOrange fa-fw"></i>
                  {{ $t('xLogOut') }}
                </b-dropdown-item>
              </b-dropdown>
            </b-navbar-nav>
          </div>
        </b-collapse>

        <b-nav-item-dropdown no-caret right class="d-md-none ml-auto" toggle-class="mobile-nav-button">
          <template #button-content>
            <i class="fas fa-bars"></i>
          </template>
          <b-dropdown-item :to="{ name: 'dashboard' }" class="mobile-nav-item">
            <i class="far colorOrange fa-house fa-fw" />
            <span class="ml-2">{{ $t('xDashboard') }}</span>
          </b-dropdown-item>
          <b-dropdown-item :to="{ name: 'support' }" class="mobile-nav-item">
            <i class="far colorOrange fa-question-circle fa-fw" />
            <span class="ml-2">{{ $t('xSupport') }}</span>
          </b-dropdown-item>
          <b-dropdown-item
            :to="{ name: 'messageCenter' }"
            class="mobile-nav-item"
            v-if="messageCenterEnabled(currentAppConfig) && showMessageIcon()"
          >
            <span class="position-relative">
              <i class="far fa-message-lines fa-fw colorOrange" />
              <i v-if="hasUnreadMessage" class="fas fa-circle colorOrange has-unread-message" />
            </span>
            <span class="ml-2">{{ $t('xMessageCenter') }}</span>
          </b-dropdown-item>
          <b-dropdown-item :to="{ name: 'accountInformation' }" class="mobile-nav-item">
            <i class="far colorOrange fa-user-circle fa-fw"></i>
            <span class="ml-2">{{ $t('xAccountSettings') }}</span>
          </b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item @click="signOut()" class="mobile-nav-item">
            <i class="far colorOrange fa-sign-out-alt fa-fw"></i>
            <span class="ml-2">{{ $t('xLogOut') }}</span>
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar>
      <b-container fluid class="wrapper">
        <transition name="fade" mode="out-in">
          <router-view />
        </transition>
      </b-container>
      <footer class="footer pl-3 my-3" sticky="true" v-if="$route.name != 'home' && $route.name != 'createAccount'">
        <b-link class="mr-3 footer-link" :to="{ name: 'support' }">Contact Us</b-link>
        <b-link class="mr-3 footer-link" :href="termsConditionsUrl" target="_blank">
          Terms & Conditions
        </b-link>
        <b-link class="mr-3 footer-link" :href="privacyPolicyUrl" target="_blank">
          Privacy Statement
        </b-link>
        <b-link class="mr-3 footer-link" :href="eulaUrl" target="_blank">
          End User License Agreement
        </b-link>
      </footer>
      <Toast></Toast>
      <FeedbackModal />
      <BannerMessageModal v-if="showBannerMessage(bannerMessage)" />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import Toast from '@/components/Toast'
import FeedbackModal from '@/components/FeedbackModal'
import BannerMessageModal from '@/components/BannerMessageModal'
import { vueAppApiBaseAddress } from '@/utils/mixins'

export default {
  components: { Toast, FeedbackModal, BannerMessageModal },
  data() {
    return {
      adminGroup: process.env.VUE_APP_ADMIN_GROUP,
      termsConditionsUrl: process.env.VUE_APP_TERMS_CONDITIONS_URL,
      eulaUrl: process.env.VUE_APP_EULA_URL,
      privacyPolicyUrl: process.env.VUE_APP_PRIVACY_POLICY_URL
    }
  },
  computed: {
    ...mapState({
      user: state => state.account.user,
      userLoaded: state => state.account.userLoaded,
      isImpersonating: state => state.auth.isImpersonating,
      impersonatedEmail: state => state.auth.impersonatedEmail,
      hasUnreadMessage: state => state.account.hasUnreadMessages,
      currentAppConfig: state => state.application.currentAppConfig,
      bannerMessage: state => state.application.bannerMessage,
      allowedUnprotected() {
        return this.$route.meta.protected == false
      }
    }),
    currentYear() {
      return new Date().getFullYear()
    }
  },
  methods: {
    showMessageIcon() {
      if (this.isImpersonating) {
        return true
      } else if (this.user) {
        if (this.user.groups && this.user.groups.includes(this.adminGroup)) return false
        else return true
      }
      return true
    },
    signIn: function() {
      sessionStorage.setItem('toRoute', JSON.stringify({ path: '/dashboard' }))
      window.location.assign(vueAppApiBaseAddress() + `Auth/SignIn`)
    },
    brandClicked: function() {
      if (this.user) {
        this.$router.push('/dashboard')
      } else {
        this.$router.push('/')
      }
    }
  },
  watch: {
    isImpersonating: function(newVal, oldVal) {
      if (newVal) this.$store.dispatch('getImpersonatedUser')
    }
  },
  mounted() {
    if (!this.isImpersonating) this.$store.dispatch('getUser')
    else this.$store.dispatch('getImpersonatedUser')
  }
}
</script>

<style lang="scss">
#grayText:active {
  color: #817c7c;
}
</style>

<style scoped lang="scss">
// Desktop menu styles
.nav-item {
  display: flex;
  align-items: center;
}
.nav-link {
  color: var(--carbon-900) !important;
  font-family: 'Roboto', sans-serif !important;
  font-size: 1.125em !important;
  border-radius: 6px !important;
  height: 40px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  text-transform: none;
  font-weight: normal;
  margin-right: 0.5rem;

  &--admin {
    color: var(--blue-700) !important;
  }

  &:hover {
    background-color: var(--carbon-100) !important;
    color: var(--carbon-900) !important;
  }

  &:focus:active {
    background-color: var(--blue-800) !important;
    color: var(--white) !important;

    i {
      color: var(--brand-400) !important;
  }

  i,
  span i {
    color: var(--carbon-900);
  }

    i.message-icon {
        color: var(--brand-400) !important;
    }

  }
  
}

.router-link-exact-active {
  font-weight: 700;

  i {
    color: var(--brand-400);
  }
}

.has-unread-message {
  position: absolute;
  font-size: 12px;
  top: -4px;
  right: 4px;
  color: var(--blue-700) !important;
}

.navbar-nav {
  position: relative;
  z-index: 9999;
  background-color: var(--white);
}
.title-nav-bar {
  max-height: 80px !important;
  background-color: var(--white);
  z-index: 40;
}
.footer {
  flex-shrink: 0;
  font-size: 0.875em;
}
</style>

<style lang="scss">
// Mobile nav styles - not sure why but the scoped styles werent working
.mobile-nav-item {
  font-size: 1.125em !important;
  .has-unread-message {
    right: -2px;
  }

  .router-link-active {
    background: var(--blue-700);
    color: white;

    i {
      color: white;
    }
  }
}

.mobile-nav-button {
  height: 44px;
  width: 44px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  color: var(--carbon-900);
  font-size: 1.25em;
  padding: 0 !important;
  border-radius: 4px;

  &:hover {
    background-color: var(--carbon-100);
    color: var(--carbon-900);
  }
}
</style>
