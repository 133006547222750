<template>
  <div class="mt-2 ml-md-2">
    <div v-if="preferredDealerLoading" class="d-flex justify-content-center">
      <span
        class="spinner-border text-warning"
        style="width: 5rem; height: 5rem;"
        data-test-hook="LoadingSpinner"
      ></span>
    </div>
    <div v-if="!preferredDealerLoading">
      <div v-if="preferredDealer && !editPreferredDealer && (isHsbApparatus() || isPortableApparatus())">
        <div class="row no-gutters mb-2">
          <div class="col">
            <h1 v-if="isPortableApparatus()" class="mb-0 pb-0" data-test-hook="PortablePageTitle">
              <i class="far fa-user-hard-hat colorOrange mr-1"></i> {{ $t('xPreferredServiceDealer') }}
            </h1>
            <h1 v-else class="mb-0 pb-0" data-test-hook="HsbPageTitle">
              <i class="far fa-user-hard-hat colorOrange mr-1"></i> {{ $t('xPreferredDealer') }}
            </h1>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <div class="row align-content-center" v-if="preferredDealer.name">
                  <div class="col d-flex align-items-center">
                    <h5 data-test-hook="DealerName" class="mb-0">{{ preferredDealer.name }}</h5>
                  </div>
                  <div
                    class="col float-right"
                    v-if="!preferredDealer.isDealerManaged && !preferredDealer.isDealerUnmonitored"
                  >
                    <button
                      type="button"
                      class="btn btn-icon-button float-right"
                      @click="openModal('deletePreferredDealer-modal')"
                      data-test-hook="DeleteDealerBtn"
                    >
                      <i class="fas fa-trash-alt" />
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p v-if="preferredDealer.topLineAddress" data-test-hook="DealerAddress1">
                      {{ preferredDealer.topLineAddress }}
                    </p>
                    <p v-if="preferredDealer.bottomLineAddress" data-test-hook="DealerAddress2">
                      {{ preferredDealer.bottomLineAddress }}
                    </p>
                    <h5 no-gutters v-if="preferredDealer.phoneNumber" data-test-hook="DealerPhoneNumber">
                      <i class="far fa-fw fa-phone-alt"></i> <strong>{{ preferredDealer.phoneNumber | phone }}</strong>
                    </h5>
                    <p v-if="preferredDealer.email" data-test-hook="DealerEmail">
                      <i class="far fa-fw fa-envelope"></i> {{ preferredDealer.email }}
                    </p>
                    <a
                      type="button"
                      v-if="preferredDealer.email"
                      class="mt-3 btn btn-outline-warning"
                      :href="'mailto:' + preferredDealer.email"
                      target="_self"
                      data-test-hook="EmailDealerBtn"
                    >
                      <i class="fas fa-envelope"></i>
                      {{ $t('xEmailDealer') }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-else-if="preferredDealer && isTankApparatus()" class="mb-2">
        <div class="row no-gutters" data-test-hook="FuelProviderPage">
          <div class="col">
            <h1 class="mb-0 pb-0" data-test-hook="FuelProviderPageTitle">
              <i class="far fa-user-hard-hat colorOrange mr-1" data-test-hook="FuelProviderPageTitleIcon" />
              {{ $t('xFuelProvider') }}
            </h1>
          </div>
        </div>
        <div class="card mt-2">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <p data-test-hook="FuelProviderName">
                  <strong>{{ preferredDealer.name }}</strong>
                </p>
                <p data-test-hook="FuelProviderRegion">
                  <i class="fa-light fa-location-dot" data-test-hook="FuelProviderRegionIcon" />
                  {{ preferredDealer.region }}
                </p>
              </div>
              <div class="cols-auto float-right">
                <button
                  type="button"
                  class="btn btn-icon-button ml-3"
                  @click="openModal('edit-fuel-provider')"
                  data-test-hook="EditFuelProviderBtn"
                >
                  <i class="fas fa-pencil" data-test-hook="EditFuelProviderBtnIcon" />
                </button>
                <button
                  type="button"
                  class="btn btn-icon-button"
                  @click="openModal('deletePreferredDealer-modal')"
                  data-test-hook="DeleteFuelProviderBtn"
                >
                  <i class="fas fa-trash-alt" data-test-hook="DeleteFuelProviderBtnIcon" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!preferredDealer">
        <div v-if="isHsbApparatus() || isPortableApparatus()">
          <div class="row mt-4">
            <div class="col d-flex align-items-center flex-column text-center">
              <i class="fal fa-user-hard-hat locate-dealer-hardhat" />
              <h4 v-if="isPortableApparatus()" class="mt-3" data-test-hook="PortPageTitle">
                {{ $t('xNoPreferredServiceDealer') }}
              </h4>
              <h4 v-else data-test-hook="NoDealerText" class="mt-3">
                {{ $t('xNoPreferredDealer') }}
              </h4>
              <p data-test-hook="DealerNotSetUpText">
                {{ $t('xPreferredDealerNotSetup') }}
              </p>
              <button
                type="button"
                v-if="isHsbApparatus()"
                class="mb-2 mt-2 preferred-dealer-button btn btn-outline-warning btn-block"
                @click="openModal('dealerNameSearch-modal')"
                data-test-hook="AddDealerBtn"
              >
                <i class="fas fa-plus" />
                {{ $t('xAddInstaller') }}
              </button>
              <button
                type="button"
                class="btn btn-block btn-warning preferred-dealer-button"
                @click="openModal('locateDealerSearch-modal')"
                data-test-hook="LocateDealerBtn"
              >
                <i class="fa fa-search"></i>
                {{ $t('xLocateADealer') }}
              </button>
            </div>
          </div>
        </div>
        <div v-else-if="isTankApparatus()">
          <div class="row mt-4" data-test-hook="FuelProviderPage">
            <div class="col-12 text-center">
              <i class="fal fa-user-hard-hat locate-dealer-hardhat" data-test-hook="NoFuelProviderPageTitleIcon" />
              <h4 class="mt-3" data-test-hook="NoFuelProviderPageTitle">
                {{ $t('xNoFuelProvider') }}
              </h4>
              <p data-test-hook="NoFuelProviderPageBodyText">{{ $t('xNoFuelProviderMessage') }}</p>

              <button
                type="button"
                class="btn btn-warning mt-2"
                @click="openModal('edit-fuel-provider')"
                data-test-hook="SelectFuelProviderBtn"
              >
                <i class="fas fa-search" data-test-hook="SelectFuelProviderBtnIcon" />
                {{ $t('xSelectFuelProvider') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Modal modalId="deletePreferredDealer-modal" size="modal-md">
      <ModalBody>
        <div class="row">
          <div class="col" v-if="isHsbApparatus" data-test-hook="DeleteDealerWarning">
            {{ $t('xDeletePreferredDealerWarning') }}
          </div>
          <div class="col" v-else-if="isTankApparatus">
            {{ $t('xRemoveFuelProviderWarning') }}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          class="btn btn-outline-warning"
          @click="closeModal('deletePreferredDealer-modal')"
          data-test-hook="CancelBtn"
        >
          {{ $t('xNoCancel') }}
        </button>
        <button
          type="button"
          class="btn btn-warning"
          @click="deletePreferredDealer(), closeModal('deletePreferredDealer-modal')"
          data-test-hook="ConfirmBtn"
        >
          {{ $t('xYesImSure') }}
        </button>
      </ModalFooter>
    </Modal>

    <Modal modalId="dealerNameSearch-modal" size="modal-lg" data-test-hook="LocateDealerModal">
      <ModalHeader :title="$t('xAddInstaller')" icon="fas fa-plus colorOrange mr-1">
        <div class="cols-auto">
          <button
            type="button"
            class="btn btn-icon-button btn-icon-button--gray"
            @click="dealerNameSearchClose()"
            data-test-hook="CloseModalBtn"
          >
            <i class="fas fa-times" />
          </button>
        </div>
      </ModalHeader>
      <ModalBody>
        <div id="searchDealerByName-results">
          <div class="row align-items-center mb-2">
            <div class="cols-auto ml-3">
              <i id="dealer-name-search-help" class="fas fa-question-circle" />
              <b-popover
                target="dealer-name-search-help"
                triggers="hover focus"
                placement="right"
                data-test-hook="AddDealerHelpText"
              >
                {{ $t('xDealerNameSearchHelp') }}
              </b-popover>
            </div>
            <div class="col">
              <div role="group" class="input-group">
                <input
                  class="form-control"
                  v-model="searchNameCriteria"
                  :placeholder="$t('xInstallerName')"
                  type="text"
                  v-on:keyup.enter="searchDealersByName()"
                  data-test-hook="DealerNameSearchField"
                />
                <div class="input-group-append">
                  <button
                    type="button"
                    class="btn btn-warning my-0 py-0"
                    @click="searchDealersByName()"
                    data-test-hook="DealerSearchBtn"
                  >
                    <span
                      v-if="searchingForDealers"
                      class="spinner-border"
                      style="width: 1.5rem; height: 1.5rem;"
                      data-test-hook="SearchSpinner"
                    ></span>
                    <i class="fas fa-search" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div v-if="installingDealers.length > 0" class="card no-border" footer-bg-variant="white">
                <div class="list-group">
                  <div
                    class="list-group-item sidenav hoverPointer searchResult py-4"
                    v-for="(dealer, index) in installingDealers"
                    :key="index"
                    @click="
                      openModal('dealerNameDetail-modal'),
                        closeModal('dealerNameSearch-modal'),
                        (installingDealer = dealer)
                    "
                    data-test-hook="DealerSearchResult"
                  >
                    <h5 data-test-hook="DealerName">{{ dealer.name }}</h5>
                    <h6 data-test-hook="DealerPhone">{{ dealer.phoneNumber | phone }}</h6>
                    <h6 data-test-hook="DealerTopAddress">{{ dealer.topLineAddress }}</h6>
                    <h6 data-test-hook="DealerBottomAddress">{{ dealer.bottomLineAddress }}</h6>
                  </div>
                </div>
                <div class="card-footer px-0">
                  <button
                    type="button"
                    class="btn btn-outline-warning btn-block"
                    @click="searchDealersByName()"
                    :disabled="searchingForDealers || noMoreResults"
                    data-test-hook="ViewMoreBtn"
                  >
                    {{ $t('xViewMore') }}
                    <span class="spinner-border" v-if="searchingForDealers" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>

    <Modal modalId="closeDealerNameSearch-modal" size="modal-md">
      <ModalBody>
        <div class="row">
          <div class="col mt-2" data-test-hook="ExitWarningText">
            {{ $t('xExitWithoutSaving') }}
          </div>
          <div class="cols-auto float-right">
            <button
              type="button"
              class="btn btn-icon-button btn-icon-button--gray"
              @click="closeModal('closeDealerNameSearch-modal')"
            >
              <i class="fas fa-times" />
            </button>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          class="btn btn-outline-warning"
          @click="closeModal('closeDealerNameSearch-modal'), openModal('dealerNameDetail-modal')"
          data-test-hook="CancelBtn"
        >
          {{ $t('xNoCancel') }}
        </button>
        <button
          type="button"
          class="btn btn-warning"
          @click="dealerNameSearchClose(), closeModal('closeDealerNameSearch-modal')"
          data-test-hook="ConfirmBtn"
        >
          {{ $t('xYesImSure') }}
        </button>
      </ModalFooter>
    </Modal>

    <Modal modalId="dealerNameDetail-modal" size="modal-lg">
      <ModalHeader :title="$t('xAddInstaller')" icon="fas fa-plus colorOrange mr-1">
        <div class="cols-auto">
          <button
            type="button"
            class="btn btn-icon-button btn-icon-button--gray"
            @click="closeModal('dealerNameDetail-modal'), openModal('closeDealerNameSearch-modal')"
            data-test-hook="CloseModalBtn"
          >
            <i class="fas fa-times" />
          </button>
        </div>
      </ModalHeader>
      <ModalBody>
        <div class="row" v-if="installingDealer">
          <div class="col">
            <h5 data-test-hook="DealerName">{{ installingDealer.name }}</h5>
            <h5 data-test-hook="DealerPhone">{{ installingDealer.phoneNumber | phone }}</h5>
            <h6 data-test-hook="DealerTopAddress">{{ installingDealer.topLineAddress }}</h6>
            <h6 data-test-hook="DealerBottomAddress">{{ installingDealer.bottomLineAddress }}</h6>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <button
              type="button"
              class="btn btn-outline-warning btn-block"
              @click="
                openModal('dealerNameSearch-modal'), closeModal('dealerNameDetail-modal'), (installingDealer = null)
              "
              data-test-hook="PreviousBtn"
            >
              {{ $t('xSearchAgain') }}
            </button>
            <button
              type="button"
              class="btn btn-warning btn-block"
              @click="locateDealerDetailSetPreferredDealer(installingDealer.externalId)"
              data-test-hook="SetDealerBtn"
            >
              <i class="fas fa-user-hard-hat" />
              {{ $t('xSetPreferredDealer') }}
              <span class="spinner-border ml-2" v-if="savingNewDealer" data-test-hook="SaveSpinner" />
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>

    <Modal ref="locateDealerSearch-modal" modalId="locateDealerSearch-modal" size="modal-lg">
      <ModalHeader :title="$t('xLocateADealer')" icon="fa fa-search colorOrange mr-1">
        <div class="cols-auto">
          <button
            type="button"
            class="btn btn-icon-button btn-icon-button--gray"
            @click="locateDealerSearchClose()"
            data-test-hook="CloseModalBtn"
          >
            <i class="fas fa-times" />
          </button>
        </div>
      </ModalHeader>
      <ModalBody :class="{'modal-height': dealers.length > 0}">
        <div>
          <b-form-input
            class="pt-5 mb-0 pb-0"
            v-model="radius"
            type="range"
            min="10"
            max="200"
            step="10"
            data-test-hook="SearchRadius"
          />
          <div class="row mt-2">
            <div class="col float-left asterisk-text" data-test-hook="MinDistance">10 {{ getDistanceUOMText() }}</div>
            <div class="cols-auto float-right asterisk-text mr-3" data-test-hook="MaxDistance">
              200 {{ getDistanceUOMText() }}
            </div>
          </div>
          <output id="rangevalue">{{ radius }}</output>
          <div role="group" class="input-group">
            <input
              class="form-control"
              v-model="dealerLocatePostalCode"
              placeholder="Postal Code"
              type="text"
              v-on:keyup.enter="searchDealers()"
              data-test-hook="PostalCodeField"
            />
            <button class="my-0 py-0 btn btn-warning" @click="searchDealers()" data-test-hook="PostalCodeSearchBtn">
              <span
                class="spinner-border"
                v-if="searchingForDealers"
                style="width: 1.5rem; height: 1.5rem;"
                data-test-hook="SearchSpinner"
              />
              <i class="fas fa-search" />
            </button>
          </div>
        </div>
        <div>
          <div class="row">
            <div class="col">
              <div class="list-group">
                <div
                  v-for="(dealer, index) in dealers"
                  :key="index"
                  class="list-group-item sidenav hoverPointer py-4 searchResult"
                  @click="selectDealer(dealer)"
                  data-test-hook="DealerSearchResult"
                >
                  <h5 data-test-hook="DealerName">{{ dealer.name }}</h5>
                  <h6 data-test-hook="DealerPhoneNumber">{{ dealer.phoneNumber | phone }}</h6>
                  <h6 data-test-hook="DealerDistance">
                    {{ $t('xDistance') }} {{ getDistanceInUom(dealer.distance) }} {{ getDistanceUOMText() }}
                  </h6>
                  <h6 data-test-hook="DealerAddressLine1">{{ dealer.address.addressLine1 }}</h6>
                  <h6 data-test-hook="DealerAddressBottomLine">
                    {{ dealer.address.city }}, {{ dealer.address.region }} {{ dealer.address.postalCode }}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
      </ModalFooter>
    </Modal>

    <Modal ref="locateDealerDetail-modal" modalId="locateDealerDetail-modal" size="modal-md">
      <ModalHeader :title="$t('xLocateADealer')" icon="fa fa-search colorOrange mr-1">
        <div class="cols-auto">
          <button
            type="button"
            class="btn btn-icon-button btn-icon-button--gray"
            @click="dealerLocateDetailClose()"
            data-test-hook="CloseModalBtn"
          >
            <i class="fas fa-times" />
          </button>
        </div>
      </ModalHeader>
      <ModalBody>
        <div v-if="dealerDetail !== null">
          <div class="row">
            <div class="col">
              <div class="row w-100 mb-2 no-gutters align-items-center">
                <div class="col">
                  <p data-test-hook="DealerDistance" class="mb-0">
                    {{ $t('xDistance') }}: {{ getDistanceInUom(dealerDetail.distance) }} {{ getDistanceUOMText() }}
                  </p>
                </div>
                <div class="cols-auto">
                  <button
                    type="button"
                    class="btn btn-icon-button"
                    @click="locateDealerDetailMap(dealerDetail.address)"
                    data-test-hook="DealerMapBtn"
                  >
                    <i class="fas fa-map-marker-alt" />
                  </button>
                </div>
              </div>
              <p data-test-hook="DealerName">
                <strong>{{ dealerDetail.name }}</strong>
              </p>
              <p data-test-hook="DealerPhone">
                <strong>{{ dealerDetail.phoneNumber | phone }}</strong>
              </p>
              <p data-test-hook="DealerAddressTop">{{ dealerDetail.address.addressLine1 }}</p>
              <p data-test-hook="DealerAddressBottom" class="mb-4">
                {{ dealerDetail.address.city }},
                {{ dealerDetail.address.region }}
                {{ dealerDetail.address.postalCode }}
              </p>

              <a
                class="d-block mb-2"
                v-if="dealerDetail.website != ''"
                :href="'http://' + dealerDetail.website"
                target="_blank"
                data-test-hook="DealerWebsite"
              >
                <i class="far fa-globe mr-2 fa-fw"></i>{{ dealerDetail.website }}
              </a>
              <a
                class="d-block mb-2"
                v-if="dealerDetail.email != ''"
                :href="'mailto:' + dealerDetail.email"
                data-test-hook="EmailDealerLink"
              >
                <i class="far fa-envelope mr-2 fa-fw"></i>{{ dealerDetail.email }}
              </a>
              <br />
              <div class="row">
                <div class="col-6">
                  <b-img :src="locateDealerClass(dealerDetail.class)" />
                </div>
              </div>
            </div>
            <div id="map"></div>
          </div>
        </div>
        <div class="mt-5">
          <div class="row">
            <div class="col">
              <button
                type="button"
                class="btn btn-outline-warning btn-block"
                @click="locateDealerDetailPrevious()"
                data-test-hook="PreviousBtn"
              >
                {{ $t('xSearchAgain') }}
              </button>
              <button
                type="button"
                class="btn btn-warning btn-block"
                @click="locateDealerDetailSetPreferredDealer(dealerDetail.id)"
                data-test-hook="SetDealerBtn"
              >
                <i class="fas fa-user-hard-hat" />
                {{ $t('xSetPreferredDealer') }}
                <a class="spinner-border ml-2" v-if="savingNewDealer" data-test-hook="SaveSpinner" />
              </button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>

    <Modal modalId="edit-fuel-provider">
      <ModalHeader
        :title="$t('xEditFuelProvider')"
        icon="fas fa-user-hard-hat colorOrange mr-1"
        data-test-hook="ModalTitle"
      >
        <div class="cols-auto">
          <button
            type="button"
            class="btn btn-icon-button btn-icon-button--gray"
            @click="closeEditFuelProvider()"
            data-test-hook="ModalCloseBtn"
          >
            <i class="fas fa-times" data-test-hook="ModalCloseBtnIcon" />
          </button>
        </div>
      </ModalHeader>
      <ModalBody>
        <div class="row">
          <div class="col">
            <h5 data-test-hook="ModalBodyText">{{ $t('xWhoDeliversYourFuel') }}</h5>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group" id="fuelProvider-group">
              <span v-click-outside="hideResults">
                <div class="input-group">
                  <input
                    class="form-control input-group-append"
                    v-model="freeformFuelProvider"
                    type="text"
                    v-on:keyup="typeAheadSearch()"
                    autocomplete="new-password"
                    data-test-hook="FuelProviderSearchValue"
                  />
                  <div class="input-group-append">
                    <button
                      type="button"
                      class="btn btn-warning my-0 py-0"
                      @click="!awaitingSearch ? typeAheadSearch() : null"
                      data-test-hook="FuelProviderSearchBtn"
                    >
                      <span class="spinner-border" v-if="awaitingSearch" style="width: 1.5rem; height: 1.5rem" />
                      <i v-else class="fas fa-search" data-test-hook="FuelProviderSearchBtnIcon" />
                    </button>
                  </div>
                </div>
                <div class="row"></div>
                <div class="row">
                  <div class="col">
                    <div class="suggested-input-list list-group" v-if="fuelProviderSearchResults">
                      <div
                        class="list-group-item hoverPointer"
                        v-for="(provider, index) in fuelProviderSearchResults"
                        :key="index"
                        @click="setFuelProvider(provider)"
                        :data-test-hook="'FuelProviderSearchList-' + index"
                      >
                        {{ provider.name }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3" v-if="fuelProviderSearchName">
                  <div class="col" data-test-hook="FuelProviderSearchSelection">{{ fuelProviderSearchName }}</div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          class="btn btn-outline-warning"
          @click="closeModal('edit-fuel-provider')"
          data-test-hook="ModalCancelBtn"
        >
          {{ $t('xCancel') }}
        </button>
        <button
          type="button"
          class="btn btn-warning"
          @click="saveFuelProvider()"
          :disabled="savingNewDealer || !fuelProviderSearchId"
          data-test-hook="ModalSaveBtn"
        >
          <span v-if="!savingNewDealer">{{ $t('xSave') }}</span>
          <span v-else><a class="spinner-border"/></span>
        </button>
      </ModalFooter>
    </Modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { getSortOrder } from '@/utils/mixins'
import * as Constants from '@/constants'

export default {
  computed: {
    ...mapState({
      currentApparatusDetails: state => state.apparatus.currentApparatusDetails,
      user: state => state.account.user,
      accountProfile: state => state.account.accountProfile
    }),
    hsbGeneratorApparatus() {
      return Constants.HsbApparatusType
    },
    fuelTankApparatus() {
      return Constants.FuelTankApparatusType
    },
    fuelProviderId() {
      return this.getPropertyValue(Constants.TuSnmpTypeFuelDealerId)
    }
  },
  data() {
    return {
      preferredDealer: null,
      preferredDealerLoading: true,
      editPreferredDealer: false,
      newDealerId: null,
      dealerIdIsValid: null,
      savingNewDealer: false,
      dealers: [],
      dealerLocatePostalCode: '',
      countryCode: 'USA',
      distanceUOMText: 'miles',
      dealerDetail: null,
      radius: 20,
      searchingForDealers: false,
      timeout: null,
      searchNameCriteria: null,
      tempSearchNameCriteria: null,
      pageNumber: 0,
      pageSize: 10,
      installingDealers: [],
      installingDealer: null,
      noMoreResults: false,
      freeformFuelProvider: null,
      fuelProviderSearchId: null,
      fuelProviderSearchName: null,
      fuelProviderSearchResults: null,
      awaitingSearch: false
    }
  },
  methods: {
    resetFuelProviderModal() {
      this.freeformFuelProvider = null
      this.fuelProviderSearchId = null
      this.fuelProviderSearchResults = null
      this.fuelProviderSearchName = null
      this.awaitingSearch = false
      this.savingNewDealer = false
    },
    isHsbApparatus() {
      if (
        this.currentApparatusDetails &&
        this.currentApparatusDetails.apparatusClassification == this.hsbGeneratorApparatus
      ) {
        return true
      } else return false
    },
    isTankApparatus() {
      if (
        this.currentApparatusDetails &&
        this.currentApparatusDetails.apparatusClassification == this.fuelTankApparatus
      ) {
        return true
      } else return false
    },
    isPortableApparatus() {
      if (
        this.currentApparatusDetails &&
        this.currentApparatusDetails.apparatusClassification == Constants.PortableGeneratorApparatusType
      ) {
        return true
      } else return false
    },
    getPropertyValue(type) {
      if (this.currentApparatusDetails) {
        if (this.currentApparatusDetails.apparatusClassification == this.hsbGeneratorApparatus) {
          let property = this.currentApparatusDetails.properties.find(p => p.type == type)
          return property != null ? property.value : null
        } else if (this.currentApparatusDetails.apparatusClassification == this.fuelTankApparatus) {
          let property = this.currentApparatusDetails.tuProperties.find(p => p.type == type)
          return property != null ? property.value : null
        }
      }
      return null
    },
    deletePreferredDealer() {
      if (this.preferredDealer) {
        this.preferredDealerLoading = true
        if (
          this.currentApparatusDetails.apparatusClassification == this.hsbGeneratorApparatus ||
          this.currentApparatusDetails.apparatusClassification == Constants.PortableGeneratorApparatusType
        ) {
          this.$store
            .dispatch('deletePreferredDealer', {
              apparatusId: this.$route.params.apparatusId,
              dealerOrgId: this.preferredDealer.organizationId
            })
            .then(response => {
              this.preferredDealer = null
            })
            .catch(error => {
              this.$store.dispatch('displayToast', {
                title: this.$t('xError'),
                message: this.$t('xFailedToDeleteDealer'),
                variant: 'danger'
              })
            })
            .finally(() => {
              this.preferredDealerLoading = false
            })
        } else if (this.currentApparatusDetails.apparatusClassification == this.fuelTankApparatus) {
          this.$store
            .dispatch('removeFuelProvider', this.$route.params.apparatusId)
            .then(response => {
              this.preferredDealer = null
              setTimeout(
                function() {
                  this.$store.dispatch('getApparatusDetails', this.$route.params.apparatusId)
                  this.$store.dispatch('displayToast', {
                    title: this.$t('xSuccess'),
                    message: this.$t('xRemoveFuelProviderSuccess'),
                    variant: 'success'
                  })
                  this.preferredDealerLoading = false
                }.bind(this),
                2000
              )
            })
            .catch(error => {
              this.$store.dispatch('displayToast', {
                title: this.$t('xError'),
                message: this.$t('xFailedToRemoveFuelProvider'),
                variant: 'danger'
              })
            })
        }
      }
    },
    setPreferredDealer() {
      this.savingNewDealer = true
      this.$store
        .dispatch('setPreferredDealer', { apparatusId: this.$route.params.apparatusId, dealerId: this.newDealerId })
        .then(response => {
          var message =
            this.currentApparatusDetails.apparatusClassification == Constants.PortableGeneratorApparatusType
              ? this.$t('xPreferredServiceDealerWasSetSuccessfully')
              : this.$t('xYouSetYourPreferredDealer')
          this.$store.dispatch('displayToast', {
            title: this.$t('xSuccess'),
            message: message,
            variant: 'success'
          })
          this.savingNewDealer = false
          this.$store
            .dispatch('getPreferredDealer', this.$route.params.apparatusId)
            .then(response => {
              if (response.status == 204) this.preferredDealer = null
              else {
                this.preferredDealer = response.data
              }
              this.preferredDealerLoading = false
              this.editPreferredDealer = false
              this.dealerNameSearchClose()
            })
            .catch(error => {
              this.preferredDealerLoading = false
            })
        })
        .catch(error => {
          this.preferredDealer = null
          var message =
            this.currentApparatusDetails.apparatusClassification == Constants.PortableGeneratorApparatusType
              ? this.$t('xUnableToSetPreferredServiceDealerTryAgainLater')
              : this.$t('xPreferredDealerDidNotUpdateInvalidDealerId')
          if (error.response.status == 404) {
            this.$store.dispatch('displayToast', {
              title: this.$t('xError'),
              message: message,
              variant: 'danger'
            })
            this.dealerIdIsValid = false
          } else {
            this.$store.dispatch('displayToast', {
              title: this.$t('xError'),
              message: this.$t('xSomethingUnexpectedWentWrong'),
              variant: 'danger'
            })
          }
          this.savingNewDealer = false
        })
    },
    searchDealersByName() {
      if (this.searchNameCriteria) {
        if (this.tempSearchNameCriteria == this.searchNameCriteria) this.pageNumber++
        else {
          this.tempSearchNameCriteria = this.searchNameCriteria
          this.installingDealers = []
          this.pageNumber = 1
          this.noMoreResults = false
        }
        this.searchingForDealers = true
        this.$store
          .dispatch('searchDealersByName', {
            name: this.searchNameCriteria,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize
          })
          .then(response => {
            if (response.status == 200) {
              this.installingDealers.push(...response.data.dealers)
              if (this.installingDealers.length < this.pageSize) {
                this.noMoreResults = true
              }
            }
          })
          .catch(error => {
            this.noMoreResults = true
          })
          .finally(() => {
            this.searchingForDealers = false
          })
      }
    },
    searchDealers() {
      this.searchingForDealers = true
      var searchCategory =
        this.currentApparatusDetails.apparatusClassification == Constants.PortableGeneratorApparatusType
          ? Constants.DealerCategoryPortablesDealers
          : Constants.DealerCategoryResidetialAndCommercialDealers
      this.$store
        .dispatch('SearchDealers', {
          category: searchCategory,
          postalCode: this.dealerLocatePostalCode,
          radius: this.getDistanceInCountryUom(this.radius),
          countryCode: this.countryCode
        })
        .then(response => {
          this.dealers = _.cloneDeep(response.data.dealers)
          this.dealers = this.dealers.sort(getSortOrder('distance'))
        })
        .catch(error => {
          if (error.response.status == 404) {
            this.dealerIdIsValid = false
          } else {
            this.$store.dispatch('displayToast', {
              title: this.$t('xError'),
              message: this.$t('xSomethingUnexpectedWentWrong'),
              variant: 'danger'
            })
          }
          this.dealers = null
        })
        .finally(() => {
          this.searchingForDealers = false
        })
    },
    selectDealer(dealer) {
      this.dealerDetail = dealer
      this.closeModal('locateDealerSearch-modal')
      this.openModal('locateDealerDetail-modal')
    },
    locateDealerSearchClose() {
      this.closeModal('locateDealerSearch-modal')
      this.dealers = null
      this.dealerLocatePostalCode =
        this.currentApparatusDetails.address?.postalCode != null ? this.currentApparatusDetails.address.postalCode : ''
    },
    dealerNameSearchClose() {
      this.closeModal('dealerNameSearch-modal')
      this.closeModal('dealerNameDetail-modal')
      this.installingDealers = []
      this.installingDealer = null
      this.searchNameCriteria = null
      this.tempSearchNameCriteria = null
      this.pageNumber = 0
      this.noMoreResults = false
    },
    locateDealerDetailSetPreferredDealer(dealerId) {
      this.newDealerId = dealerId
      this.preferredDealer = dealerId
      this.setPreferredDealer()
      this.closeModal('locateDealerDetail-modal')
      this.dealers = null
      this.dealerLocatePostalCode =
        this.currentApparatusDetails.address?.postalCode != null ? this.currentApparatusDetails.address.postalCode : ''
    },
    locateDealerDetailPrevious() {
      this.closeModal('locateDealerDetail-modal')
      this.openModal('locateDealerSearch-modal')
      this.dealerDetail = null
    },
    locateDealerDetailMap(address) {
      window.open(
        'http://maps.google.com/?q=' +
          address.addressLine1 +
          ' ' +
          address.city +
          ', ' +
          address.region +
          ' ' +
          address.postalCode,
        '_blank'
      )
    },
    locateDealerDetailOpenDealerUrl(url) {
      window.open('http://' + url, '_blank')
    },
    dealerLocateDetailClose() {
      this.closeModal('locateDealerDetail-modal')
      this.dealerDetail = null
      this.dealerLocatePostalCode =
        this.currentApparatusDetails.address?.postalCode != null ? this.currentApparatusDetails.address.postalCode : ''
    },
    getDistanceUOMText() {
      let defaultText = ''
      switch (this.countryCode) {
        case 'USA':
          defaultText = 'mi'
          break
        default:
          defaultText = 'km'
      }

      let uomText = defaultText
      if (this.user.mobileLinkSettings?.displaySettings?.distanceUom != undefined) {
        uomText = this.user.mobileLinkSettings.displaySettings.distanceUom == 'miles' ? 'mi' : 'km'
      }
      return uomText
    },
    locateDealerClass: function(dealerClass) {
      switch (dealerClass) {
        case 2:
          return require('@/assets/images/select.svg')
          break
        case 3:
          return require('@/assets/images/elite.svg')
          break
        case 4:
          return require('@/assets/images/premier.svg')
          break
        case 6:
          return require('@/assets/images/power-pro-select.svg')
          break
        case 7:
          return require('@/assets/images/power-pro-elite.svg')
          break
        case 8:
          return require('@/assets/images/power-pro-premier.svg')
          break
        case 9:
          return require('@/assets/images/elite-plus.svg')
          break
        case 10:
          return require('@/assets/images/power-pro-elite-plus.svg')
          break
      }
    },
    getIcon: function(status) {
      if (status == 'ready') {
        return require('@/assets/images/ReadyStatus.svg')
      }
      if (status == 'fault') {
        return require('@/assets/images/FaultStatus.svg')
      }
      if (status == 'warning') {
        return require('@/assets/images/WarningStatus.svg')
      }
      return require('@/assets/images/UnknownStatus.svg')
    },
    getDistanceInUom(distance) {
      //if US and distanceUom is miles or nothing
      //return value
      if (
        (!this.user.mobileLinkSettings?.displaySettings?.distanceUom ||
          this.user.mobileLinkSettings.displaySettings.distanceUom == 'miles') &&
        this.accountProfile.address.country == 'US'
      ) {
        return distance
      }

      //if not US and UOM = miles or undefined
      //convert to miles
      if (
        (!this.user.mobileLinkSettings?.displaySettings?.distanceUom ||
          this.user.mobileLinkSettings.displaySettings.distanceUom == 'miles') &&
        this.accountProfile.address.country != 'US'
      ) {
        return Math.round(distance / 1.609)
      }

      //if is US and UOM = km
      //convert to km
      if (
        this.user.mobileLinkSettings.displaySettings.distanceUom == 'kilometers' &&
        this.accountProfile.address.country == 'US'
      ) {
        return Math.round(distance * 1.609)
      }

      //if != US and UOM is km
      //return value
      if (
        this.user.mobileLinkSettings.displaySettings.distanceUom == 'kilometers' &&
        this.accountProfile.address.country != 'US'
      ) {
        return distance
      }
    },
    getDistanceInCountryUom(distance) {
      //if US and distanceUom is miles or nothing
      //return value
      if (
        (!this.user.mobileLinkSettings?.displaySettings?.distanceUom ||
          this.user.mobileLinkSettings.displaySettings.distanceUom == 'miles') &&
        this.accountProfile.address.country == 'US'
      ) {
        return distance
      }

      //if not US and UOM = miles or undefined
      //convert to km
      if (
        (!this.user.mobileLinkSettings?.displaySettings?.distanceUom ||
          this.user.mobileLinkSettings.displaySettings.distanceUom == 'miles') &&
        this.accountProfile.address.country != 'US'
      ) {
        return Math.round(distance * 1.609)
      }

      //if is US and UOM = km
      //convert to miles
      if (
        this.user.mobileLinkSettings.displaySettings.distanceUom == 'kilometers' &&
        this.accountProfile.address.country == 'US'
      ) {
        return Math.round(distance / 1.609)
      }

      //if != US and UOM is km
      //return value
      if (
        this.user.mobileLinkSettings.displaySettings.distanceUom == 'kilometers' &&
        this.accountProfile.address.country != 'US'
      ) {
        return distance
      }
    },
    typeAheadSearch() {
      if (this.timeout) {
        this.awaitingSearch = false
        clearTimeout(this.timeout)
      }
      if (this.freeformFuelProvider.length < 3) {
        this.fuelProviderSearchResults = []
        return
      }
      if (!this.awaitingSearch) {
        var request = {
          name: this.freeformFuelProvider,
          //region: this.accountProfile.address.region,
          pageNumber: 1,
          pageSize: 10
        }
        this.timeout = setTimeout(() => {
          this.$store
            .dispatch('typeAheadFuelProviderSearch', request)
            .then(response => {
              if (response.status == 204) this.fuelProviderSearchResults = []
              else this.fuelProviderSearchResults = response.data
            })
            .catch(error => {
              this.fuelProviderSearchResults = []
            })
            .finally(() => {
              this.awaitingSearch = false
            })
        }, 1000)
      }
      this.awaitingSearch = true
    },
    setFuelProvider(provider) {
      this.fuelProviderSearchId = provider.id
      this.fuelProviderSearchName = provider.name
      this.freeformFuelProvider = null
      this.hideResults()
    },
    hideResults() {
      this.fuelProviderSearchResults = null
    },
    saveFuelProvider() {
      this.savingNewDealer = true
      let request = {
        apparatusId: this.$route.params.apparatusId,
        fuelDealerId: this.fuelProviderSearchId
      }
      this.$store
        .dispatch('updateTankUtilityApparatus', request)
        .then(response => {
          setTimeout(
            function() {
              this.$store.dispatch('getApparatusDetails', this.$route.params.apparatusId)
              this.$store.dispatch('displayToast', {
                title: this.$t('xSuccess'),
                message: this.$t('xFuelProviderSuccess'),
                variant: 'success'
              })
              this.closeModal('edit-fuel-provider')
              this.savingNewDealer = false
            }.bind(this),
            5000
          )
        })
        .catch(error => {
          this.$store.dispatch('displayToast', {
            title: this.$t('xError'),
            message: this.$t('xUnableToSaveChanges'),
            variant: 'danger'
          })
          this.savingNewDealer = false
        })
    },

    getPreferredDealer() {
      this.$store
        .dispatch('getPreferredDealer', this.$route.params.apparatusId)
        .then(response => {
          if (response.status == 204) this.preferredDealer = null
          else {
            this.preferredDealer = response.data
          }
          this.preferredDealerLoading = false
        })
        .catch(error => {
          this.preferredDealerLoading = false
        })
      this.dealerLocatePostalCode =
        this.currentApparatusDetails.address?.postalCode != null ? this.currentApparatusDetails.address.postalCode : ''
      switch (this.currentApparatusDetails.address.country) {
        case 'CA':
          this.countryCode = 'CAN'
          break
        default:
          this.countryCode = 'USA'
      }
    },
    getFuelProvider() {
      this.$store
        .dispatch('getFuelProvider', this.fuelProviderId)
        .then(response => {
          if (response.status == 204) this.preferredDealer = null
          else {
            this.preferredDealer = response.data
          }
        })
        .catch(error => {})
        .finally(() => {
          this.preferredDealerLoading = false
        })
    },
    closeEditFuelProvider() {
      this.resetFuelProviderModal()
      this.closeModal('edit-fuel-provider')
    }
  },
  watch: {
    radius: function(newVal, oldVal) {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      if (newVal && newVal != oldVal && this.dealerLocatePostalCode) {
        this.timeout = setTimeout(
          function() {
            this.searchDealers()
          }.bind(this),
          1000
        )
      }
    },
    currentApparatusDetails: function(newVal, oldVal) {
      if (newVal) {
        if (
          newVal.apparatusClassification == this.hsbGeneratorApparatus ||
          newVal.apparatusClassification == Constants.PortableGeneratorApparatusType
        ) {
          this.getPreferredDealer
        } else if (newVal.apparatusClassification == this.fuelTankApparatus) {
          if (this.fuelProviderId) {
            this.getFuelProvider()
          } else {
            this.preferredDealer = null
            this.preferredDealerLoading = false
          }
        }
      }
    }
  },
  mounted() {
    if (this.currentApparatusDetails) {
      if (
        this.currentApparatusDetails.apparatusClassification == this.hsbGeneratorApparatus ||
        this.currentApparatusDetails.apparatusClassification == Constants.PortableGeneratorApparatusType
      ) {
        this.getPreferredDealer()
      } else if (this.currentApparatusDetails.apparatusClassification == this.fuelTankApparatus) {
        if (this.fuelProviderId) {
          this.getFuelProvider()
        } else {
          this.preferredDealer = null
          this.preferredDealerLoading = false
        }
      } else {
        this.preferredDealer = null
        this.preferredDealerLoading = false
      }
    }
    if (!this.accountProfile) {
      this.$store.dispatch('getAccountProfile')
    }
  }
}
</script>
<style scoped lang="scss">
.modal-height {
  overflow-y: auto;
  height: 80vh;
}
.edit-dealer-card-footer {
  border: none;
  background-color: var(--white);
}
.locate-dealer-hardhat {
  color: var(--carbon-600);
  font-size: 60px;
}
.searchResult:hover {
  background-color: var(--blue-100);
}
.preferred-dealer-button {
  width: 320px;
}
</style>
